//@import url("https://use.fontawesome.com/releases/v5.0.11/css/all.css");



.abj_annuel_table > thead > tr > th {
    border: 1px solid #000;
}

.abj_annuel_table > tbody > tr > td {
    padding: 0px !important;
    border: 1px solid blue;
    margin: 0px !important;
}

.abj_annuel_table > tbody > tr > td.inputs {
    width: 65px !important;
    padding: 2px !important;
    position: relative;
}

.abj_annuel_table > tbody > tr > td > div > input {
    /* width: 100% !important;
                                                            padding: 0px;
                                                            margin: 0px;
                                                            background: #fff;
                                                            border: none;
                                                            height: 25px;
                                                            text-align: center; */
    width: 100% !important;
    padding: 0px;
    margin: 0px;
    background: transparent;
    border: none;
    height: 11px;
    float: left;
}

.active_td,
.active_td > div > input {
    background: #2296ba29 !important;
}

.red_line {
    background: #ee646499 !important;
}

.absolute {
    position: absolute;
}

.arrow {
    cursor: pointer;
    position: absolute;
    right: 4px;
    top: 0px;
}

.arrowdown {
    color: red;
}

.arrowup {
    color: green;
}

.mar_15 {
    margin-top: 15px;
}

.input_coef {
    border: none;
    width: 60px;
    min-height: 34px !important;
    background: #dceee6;
    padding: 10px;
}





// input checkbox for recalculer les objectifs
.label-checks{
	position: relative;
	cursor: pointer;
	color: #666;
	font-size: 14px;
}

input[type="checkbox"].check_objs{
	position: absolute;
	right: 9000px;
}

/*Check box*/
input[type="checkbox"] + .label-text:before{
	content: "\f0c8";
	//content: "\f096";
	//font-family: "Font Awesome 5 Free";
	font-family: "FontAwesome";
	//speak: none;
	font-style: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing:antialiased;
	width: 1em;
	display: inline-block;
    margin-right: 5px;
    color: #c4c4c4;
}

input[type="checkbox"]:checked + .label-text:before{
	content: "\f14a";
	//content: "\f046";
	color: #2980b9;
	animation: effect 250ms ease-in;
	font-weight: 900;
}

@keyframes effect{
	0%{transform: scale(0);}
	25%{transform: scale(1.3);}
	75%{transform: scale(1.4);}
	100%{transform: scale(1);}
}


// flex-checkers

.flex_checkers{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    div {
        width: 50%;
    }
}

